
.user-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #F5F9FF 0%, rgba(255, 255, 255, 0) 87.5%);
    z-index: -1;
}


.sliderToLeft {

    
    animation: sliderToLeft 0.3s ease;
}

@keyframes sliderToLeft {
    to {
        left: -100%;
        opacity: 1;
    }
}

.contact-item {
    border: 1px solid #19810480;
    border-radius: 10px;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1), -5px -5px 0px #19810480;
}


#select-py3 * {
    padding: 12px 0;
}

.dashboard-table {
    width: 100%;
    color: var(--text-secondary);
    /* overflow: hidden; */
}

.dashboard-table thead  tr:first-child > td {   

    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    
}

.dashboard-table  tr > td:first-child {
    width: 50px;
}

.dashboard-table  tr > td {
    border-left: 2px solid #EFEFEF;
    padding: 32px 0;
    text-align: center;
    font-size: 18px;    
}

.dashboard-table tr:not(:first-child) > td:nth-child(even) {
  font-weight: 500;
}




.dashboard-table  tr:nth-child(even) {
    background: rgba(26, 115, 232, 0.04);
}

.checkbox-custom {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background-color: rgba(26, 115, 232, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
        
}

.checkbox-custom > span {
    display: none;
}

.checkbox-custom > input[type="checkbox"]:checked ~ span {
    display: block;
}

.image-tool__caption { display: none; }

.select-parent-dashboard {
    position: relative;
    z-index: 2;
}

.select-children-dashboard_unactive {
    position: absolute;
    top: -20%;
    left: 0;
    height: auto;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 300;
    opacity: 0;
    z-index: 1;
    user-select: none;
    pointer-events: none;
    cursor: default; 
    transition: all ease 0.3s;
}

.select-children-dashboard {
    top: 100% !important;
    opacity: 1;
    user-select: auto;
    pointer-events: all;
}

.history-wap_child {

    transition: all 0.4s ease;
    opacity: 0;
}

.history-wap:hover .history-wap_child {
    transform: translateY(10px);
    opacity: 1;

}

.bg-topic_child::after {
    position: absolute;
    content: "";
    display: block;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
}

.note_title-topic {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(51, 102, 204, 0) 0%, var(--primary) 56.77%);
    border-radius: 0px 0px 10px 10px;
}

